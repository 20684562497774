import React from "react";
import { Form } from "react-bootstrap";

function RequiredField(props) {
  const { label = "Required Field"} = props;
  return (
    <Form.Control.Feedback
      className={`invalid-feedback rounded text-white bg-danger px-1 font-weight-bold text-center position-relative speech-bubble mt-2`}
      type="invalid"
    >
      {label}
    </Form.Control.Feedback>
  );
}

export default RequiredField;
