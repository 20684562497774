import React from "react";
import { Form } from "react-bootstrap";

function ProductSelect(props) {
  const {
    onChange,
    products = [],
    required = false,
    readOnly = false,
    value = "",
  } = props;
  const productOptions = products.map((prod, index) => {
    return (
      <option value={prod.productId} key={index}>
        {prod.name}
      </option>
    );
  });
  return (
    <Form.Control
      as="select"
      required={required}
      className="custom-select bg-white"
      name="productId"
      value={value}
      disabled={readOnly}
      onChange={(e) => {
        onChange(e);
      }}
    >
      <option value="" disabled>
        Select
      </option>
      {productOptions}
    </Form.Control>
  );
}

export default ProductSelect;
