import React from 'react';
import request from 'superagent';
import { Form } from "react-bootstrap";

import ProductSelect from "./ProductSelect";
import PlatformSelect from "./PlatformSelect";
import RequiredField from "./RequiredField";

export default class DeviceRequest extends React.Component {
    constructor(props) 
    {
        super(props);
        this.state = {
            platforms: [],
            products: [],
            eccLevel: "L",
            version: 5,
            magnification: 10,
            numParts: 50,
            numPanels: 200,
            validated: false,
        };
    }
    componentWillMount() 
    {
        request.post(this.props.apiUrl + 'devices/2333/init')
            .send({
                csmToken: this.props.token
            })
            .then(response => {
                let platforms = response.body.platforms;
                let products = response.body.products;
                this.setState({
                    products:products,
                    platforms:platforms,
                });
        });
    }
    handleChange = (e) => {
        const { name, type, value } = e.target;
        const val = type === "number" ? parseFloat(value) : value;
        return this.setState({ [name]: val });
      };

    onHandleSubmit(e) 
    {
        e.preventDefault();
        const { lot, numPanels, numParts, eccLevel, version, magnification, productId, platforms, platformId, modelRev, firmware, structAddr } = this.state;
        const { apiUrl, token } = this.props;

//       const product = products.find(p => p.productId === productId);
        const platform = platforms.find(p => p.platformId === platformId);

        console.log("handle submit: platformId " + platformId + " productId "+productId+" numParts "+numParts);

          const form = e.currentTarget;
          if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
          } else {
            const body = {
                platform: platform.name,
                productId,
                lot,
                numPanels,
                numParts,
                eccLevel,
                version,
                magnification,
                modelRev, 
                firmware,
                structAddr,
                csmToken: this.props.token,
              };
            request.post(apiUrl + 'devices/2333/generateLot')
                .set("auth-token", token)
                .send(body)
                .responseType('blob')
                .then(response => {
                    let url = URL.createObjectURL(response.body)
                    let a = document.createElement('a')
                    a.style = 'display: none';
                    document.body.appendChild(a)
                    a.href = url;
                    a.download = 'LocatorX_lot_'+lot+'.csv'
                    a.click()
                    window.URL.revokeObjectURL(url)                
                });
                this.setState({
                    lot:lot+1,
                });

        }
    }

    handleFailure(errors) {
        console.log("errors" + errors);
    }
    saveProductToState = (e) => {
        const productId = e.target.value;
//        const product = this.state.products.find(p => p.productId === productId);

        this.setState({ 
            productId: productId 
        });
    };
    savePlatformToState = (e) => {
        const platformId = e.target.value;
        const platform = this.state.platforms.find(p => p.platformId === platformId);

        this.setState({ 
                platformId: platformId,
                modelRev: platform.latestRevision,
                firmware: platform.latestFirmware,
                structAddr: platform.structAddr,
                lot: (platform.lastLot ? platform.lastLot + 1 : 1),
            });
    };
    
    render() 
    {
        const { validated, products, platforms, productId, numParts, numPanels, eccLevel, version, magnification, platformId } = this.state;
        const { modelRev, firmware, lot, structAddr } = this.state;

        return (
            <div>
                <div className="container-fluid mt-3">
                    <h3>Generate Devices</h3>
                    <Form  
                        noValidate
                        validated={validated}
                        onSubmit={(e) => {
                            this.onHandleSubmit(e);
                          }}
                        className="form-horizontal px-3 d-flex flex-column justify-content-center align-items-center"
                        >
                        <Form.Row>
                            <Form.Group className="col-md-6 my-3">
                                <Form.Label className="h6">Platform</Form.Label>
                                <PlatformSelect
                                    onChange={this.savePlatformToState}
                                    platforms={platforms}
                                    required={true}
                                    readOnly={false}
                                    value={platformId}
                                />
                              <RequiredField />                                
                            </Form.Group>
                            <Form.Group className="col-md-6 my-3">
                                <Form.Label className="h6">Product</Form.Label>
                                <ProductSelect
                                    onChange={this.saveProductToState}
                                    products={products}
                                    required={true}
                                    readOnly={false}
                                    value={productId}
                                />
                              <RequiredField />                                
                            </Form.Group>
                            <Form.Group className="col-md-4 my-3">
                                <Form.Label className="h6">Lot</Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    name="lot"
                                    aria-label="Large"
                                    defaultValue={lot}
                                    onChange={this.handleChange}
                                    />
                            </Form.Group>
                            <Form.Group className="col-md-4 my-3">
                                <Form.Label className="h6">Number of Panels</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="numPanels"
                                    aria-label="Large"
                                    defaultValue={numPanels}
                                    onChange={this.handleChange}
                                    />
                            </Form.Group>
                            <Form.Group className="col-md-4 my-3">
                                <Form.Label className="h6">Number of Parts</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="numParts"
                                    aria-label="Large"
                                    defaultValue={numParts}
                                    onChange={this.handleChange}
                                    />
                            </Form.Group>
                            <Form.Group className="col-md-4 my-3" >
                                <Form.Label className="h6">Model/Revision</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="modelRev"
                                    aria-label="Large"
                                    defaultValue={modelRev}
                                    onChange={this.handleChange}
                                    />
                            </Form.Group>
                            <Form.Group className="col-md-4 my-3">
                                <Form.Label className="h6">Firmware Version</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firmware"
                                    aria-label="Large"
                                    defaultValue={firmware}
                                    onChange={this.handleChange}
                                    />
                            </Form.Group>
                            <Form.Group className="col-md-4 my-3">
                                <Form.Label className="h6">Struct Addr</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="structAddr"
                                    aria-label="Large"
                                    defaultValue={structAddr}
                                    onChange={this.handleChange}
                                    />
                            </Form.Group>
                            <Form.Group className="col-md-4 my-3">
                                <Form.Label className="h6">ECC Level</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="eccLevel"
                                    aria-label="Large"
                                    defaultValue={eccLevel}
                                    onChange={this.handleChange}
                                    />
                            </Form.Group>
                            <Form.Group className="col-md-4 my-3">
                                <Form.Label className="h6">Version</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="version"
                                    aria-label="Large"
                                    defaultValue={version}
                                    onChange={this.handleChange}
                                    />
                            </Form.Group>
                            <Form.Group className="col-md-4 my-3">
                                <Form.Label className="h6">Magnification</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="magnification"
                                    aria-label="Large"
                                    defaultValue={magnification}
                                    onChange={this.handleChange}
                                    />
                            </Form.Group>
                            <Form.Group className="col-md-12 text-center my-3">
                                <button type="submit" className="btn btn-primary">
                                Submit
                                </button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </div>
            </div>
        );
    }
}
